@font-face {
    font-family: 'Neris-Light';
    src: url('../fonts/Neris-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Neris-Thin';
    src: url('../fonts/Neris-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'Neris-SemiBold';
    src: url('../fonts/Neris-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'RosveliT';
    src: url('../fonts/RosveliT-Regular.ttf') format('truetype');
}

