.feature-box {
    position: relative;
    width: 100%; /* Ensure full width */
    padding: 20px 0; /* Vertical padding for scallops */
    z-index: 0; /* Base layer */
}

.feature-box::before,
.feature-box::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 20px; /* height of the scallop */
    background: url('https://silver-stream-bucket.s3.amazonaws.com/scallop_border_green_spaced_perfect.svg') repeat-x center/cover; 
    z-index: 1; /* Above the base layer */
    background-size: 100% auto;
}

.feature-box::before {
    top: 0; 
}

.feature-box::after {
    bottom: 0;
}

.feature-content {
    position: relative;
    z-index: 2; /* Above the scallops */
    padding-left: 10px;  /* Add horizontal padding */
    padding-right: 10px;
}

.scallop-container {
    overflow: hidden; 
    position: relative;
    height: 8px; /* 1/4 of the scallop's height */
}

.feature-box::before {
    top: -12px; /* Move it up to only show 1/4 */
}

.feature-box::after {
    bottom: -12px; /* Move it down to only show 1/4 */
}
